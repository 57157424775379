import React, { useState } from "react";
import "./Header.css";
import Logo from "../../assets/logo.png";
import Bars from "../../assets/bars.png";
import Logo1 from "../../assets/logo1.png";
import { Link } from 'react-scroll'

const Header = () => {
  const mobile = window.innerWidth <= 768 ? true : false;
  const [menuOpen, setMenuOpen] = useState(false);
  return (
    <div className="header">
      <div className="logo">
        <img src={Logo1} alt="" className="logo-l" />
        <img src={Logo} alt="" className="logo-r" />
      </div>
      {menuOpen === false && mobile === true ? (
        <div
          style={{backgroundColor:" var(--appColor)",
          padding: "0.5rem",
          borderRadius:"5px",
        }}
        onClick={() => setMenuOpen(true)}
        >
          <img src={Bars} alt="" style={{width: '1.5rem' , height:'1.5rem'}} />
        </div>
      ) : (
        <ul className="header-menu">
          <li>
            <Link
            onClick={() => setMenuOpen(false)}
            to='home'
            activeClass="active"
            span={true}
            smooth={true}
            >Home</Link>
          </li>
          <li onClick={()=> setMenuOpen(false)}>
          <Link
            onClick={() => setMenuOpen(false)}
            to='program'
            span={true}
            smooth={true}
            >Programs</Link>
          </li>
          <li onClick={()=> setMenuOpen(false)}>
          <Link
            onClick={() => setMenuOpen(false)}
            to='reason'
            span={true}
            smooth={true}
            >Why us</Link>
          </li>
          <li onClick={()=> setMenuOpen(false)}>
          <Link
            onClick={() => setMenuOpen(false)}
            to='plan'
            span={true}
            smooth={true}
            >Plans</Link>
          </li>
          <li>
            <Link
            onClick={()=> setMenuOpen(false)}
            to="testimonials"
            span={true}
            smooth={true}
            >Testimonials</Link>
          </li>
        </ul>
      )}
    </div>
  );
};

export default Header;
