import "./App.css";
import Hero from "./component/Hero/Hero";
import Program from "./component/program/Program";
import Reason from "./component/Reason/Reason";
import Plan from "./component/Plan/Plan";
import Join from "./component/Join/Join";
import Footer from "./component/Footer/Footer";
import Testimonial from "./component/Testimonial/Testimonial";
function App() {
  return (
    <div className="App">
      <Hero></Hero>
      <Program></Program>
      <Reason></Reason>
      <Plan></Plan>
      <Testimonial></Testimonial>
      <Join></Join>
      <Footer></Footer>
    </div>
  );
}

export default App;
