import React from "react";
import "./Plan.css";
import { plansData } from "../../data/plansData";
import whitetick from "../../assets/whiteTick.png";

const Plan = () => {
  return (
    <div className="plan-container" id="plan">
      <div className="blur blur-plan1"></div>
      <div className="blur blur-plan2"></div>
      <div className="program-header" style={{ gap: "2rem" }}>
        <span className="stroke-text">ready to start</span>
        <span>your journy</span>
        <span className="stroke-text">now with us</span>
      </div>

      {/* plan card */}

      <div className="plans">
        {plansData.map((plan, i) => (
          <div className="plan" key={i}>
            {plan.icon}
            <span>{plan.name} </span>
            <span>${plan.price}</span>

            <div className="features">
              {plan.features.map((feature, i) => (
                <div className="feature">
                  <img src={whitetick} alt="" />
                  <span key={i}>{feature}</span>
                </div>
              ))}
            </div>
            <div>
              <span>See More benefit -></span>
            </div>
            <button className="btn">Join Now</button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Plan;
